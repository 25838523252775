import get from "lodash/get";
import type { FieldError, FieldValues } from "react-hook-form";
import { useController, useFormContext } from "react-hook-form";
import type { DropzoneProps } from "~/components/inputs/dropzone";
import { Dropzone } from "~/components/inputs/dropzone";
import { InputContainer } from "~/components/inputs/field-container";
import type { FileOriginType, FileStatusType } from "~/types/database";
import type { RHFLabeledInputProps } from "~/types/forms/inputs";
interface RHFDropzoneProps {
  accept: DropzoneProps["accept"];
  applicationId?: string;
  liquidationId?: string;
  pdfConfigId?: string;
  uploadToOrigin: FileOriginType;
  fileStatus?: FileStatusType;
  removeFromDBOnDelete?: boolean;
  multiple?: DropzoneProps["multiple"];
}
export const RHFLabeledDropzone = <T extends FieldValues,>({
  containerClassName,
  name,
  label,
  description,
  required,
  rules,
  uploadToOrigin,
  fileStatus,
  removeFromDBOnDelete,
  multiple,
  ...rest
}: RHFLabeledInputProps<T> & RHFDropzoneProps) => {
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext();
  const error = get(errors, name) as FieldError | undefined; // FIXME: Hack but cannot make the right type work...

  const {
    field
  } = useController({
    name,
    control,
    rules
  });
  return <InputContainer label={label} description={description} name={name} error={error} required={required} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="RHFLabeledDropzone" data-sentry-source-file="dropzone.tsx">
      <Dropzone ref={field.ref} uploadToOrigin={uploadToOrigin} uploadedFiles={field.value ?? []} isError={Boolean(error)} removeFromDBOnDelete={removeFromDBOnDelete} fileStatus={fileStatus} onUploadedFilesChange={e => {
      rules?.onChange?.(e);
      return field.onChange(e);
    }} multiple={multiple ?? true} {...rest} data-sentry-element="Dropzone" data-sentry-source-file="dropzone.tsx" />
    </InputContainer>;
};