import { ArrowUpOnSquareIcon, DocumentArrowDownIcon, PaperClipIcon, TrashIcon } from "@heroicons/react/24/outline";
import { TRPCClientError } from "@trpc/client";
import clsx from "clsx";
import keys from "lodash/keys";
import mime from "mime-types";
import type { Dispatch, SetStateAction } from "react";
import { createContext, forwardRef, useContext, useEffect, useState } from "react";
import type { DropzoneOptions } from "react-dropzone";
import { ErrorCode as DropzoneErrorCode, useDropzone } from "react-dropzone";
import type { FileFragment } from "@monwbi/hasura";
import { Button } from "~/components/button";
import { Dialog } from "~/components/dialog";
import { InputContainer } from "~/components/inputs/field-container";
import { ConfirmDeletionDialog } from "~/components/modals/file";
import { Typography } from "~/components/typography";
import { ERROR_RESPONSES } from "~/constants/errors";
import { useSessionData } from "~/hooks/session";
import type { FileOriginType, FileStatusType } from "~/types/database";
import type { LabeledInputProps } from "~/types/forms/inputs";
import { downloadFile, uploadFile } from "~/utils/files";
import { Can } from "~/utils/permissions";
import { trpc } from "~/utils/trpc";
export type DropzoneAdditionalProps = Pick<DropzoneOptions, "accept" | "maxSize" | "multiple" | "disabled"> & {
  isError?: boolean;
};

// Need to use a mapping_key because of the way the API is designed. We create a mapping key for each file so it can be recognized by the mapper
type LoadingFileType = File;
export type UploadedFileType = FileFragment;
type RejectedFileType = {
  file: File;
  errorMessage: string;
};
type Files = {
  loadingFiles: LoadingFileType[];
  uploadedFiles: UploadedFileType[];
  rejectedFiles: RejectedFileType[];
};
const defaultFiles: Files = {
  loadingFiles: [],
  uploadedFiles: [],
  rejectedFiles: []
};
export const CustomDropzoneErrorCodes = {
  ...DropzoneErrorCode,
  Duplicate: "duplicate",
  FailedUpload: "failed-upload"
} as const;

/**
 * Get the error message from a dropzone code, if it is an error code.
 *
 * @param code - The dropzone code.
 * @returns The error message.
 */
const getErrorFromDropzoneCode = ({
  errorCode,
  opts
}: {
  errorCode: string;
  opts?: {
    maxSize: number;
  };
}) => {
  switch (errorCode) {
    case CustomDropzoneErrorCodes.FileInvalidType:
      return ERROR_RESPONSES.files.invalid_format;
    case CustomDropzoneErrorCodes.FileTooLarge:
      return ERROR_RESPONSES.files.max_size(opts?.maxSize);
    case CustomDropzoneErrorCodes.Duplicate:
      return ERROR_RESPONSES.files.duplicate;
    case CustomDropzoneErrorCodes.FailedUpload:
      return ERROR_RESPONSES.files.failed_to_upload;
    default:
      return ERROR_RESPONSES.files.failed_to_upload;
  }
};
export type DropzoneProps = Pick<DropzoneOptions, "accept" | "maxSize" | "multiple" | "disabled"> & {
  readOnly?: boolean;
  removeFromDBOnDelete?: boolean;
  isError?: boolean;
  applicationId?: string;
  liquidationId?: string;
  pdfConfigId?: string;
  uploadToOrigin: FileOriginType;
  fileStatus?: FileStatusType;
  uploadedFiles: UploadedFileType[];
  onUploadedFilesChange?: (files: UploadedFileType[]) => void;
};
type DropZoneContextType = Pick<DropzoneProps, "readOnly" | "removeFromDBOnDelete"> & {
  files: Files;
  setFiles: Dispatch<SetStateAction<Files>>;
  onUploadedFilesChange?: (files: UploadedFileType[]) => void;
};
const FilesContext = createContext<DropZoneContextType>({
  readOnly: false,
  removeFromDBOnDelete: false,
  files: defaultFiles,
  setFiles: () => null,
  onUploadedFilesChange: () => null
});
export const Dropzone = forwardRef<HTMLDivElement, React.PropsWithoutRef<DropzoneProps>>(function Dropzone({
  readOnly = false,
  removeFromDBOnDelete = false,
  applicationId,
  liquidationId,
  pdfConfigId,
  uploadToOrigin,
  fileStatus,
  uploadedFiles,
  onUploadedFilesChange,
  ...props
}, ref) {
  const [files, setFiles] = useState({
    ...defaultFiles,
    uploadedFiles
  });
  const {
    data: sessionData
  } = useSessionData();

  // This is an incorrect fix and the state synchronization between the parent and the child should be removed to fix the properly... (Not done because pressing demo)
  // TODO(dev-session): Fix this (It is a concrete example of why you should not use state synchronization between parent and child)
  useEffect(() => {
    if (uploadedFiles.map(file => file.id).join(",") !== files.uploadedFiles.map(file => file.id).join(",")) {
      setFiles(prevFiles => ({
        ...prevFiles,
        uploadedFiles
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles]);
  return <FilesContext.Provider value={{
    readOnly,
    removeFromDBOnDelete,
    files,
    setFiles,
    onUploadedFilesChange
  }}>
      <div className="flex flex-col items-center space-y-4">
        <DroppedFiles />
        {!readOnly && <NativeDropzone {...props} disabled={props.disabled || files.uploadedFiles.length > 0 && props.multiple === false} ref={ref} onDrop={async (acceptedFiles, rejectedFiles) => {
        // ACCEPTED FILES

        // Set files as loading
        setFiles(prevFiles => {
          return {
            ...prevFiles,
            rejectedFiles: [],
            loadingFiles: [...prevFiles.loadingFiles, ...acceptedFiles]
          };
        });
        const newUploadedFiles: FileFragment[] = [];
        await Promise.all(acceptedFiles.map(async file => {
          try {
            const uploadedFile = await uploadFile({
              application_id: applicationId,
              liquidation_id: liquidationId,
              pdf_config_id: pdfConfigId,
              created_by: sessionData?.user.id,
              file_origin: uploadToOrigin,
              status: fileStatus,
              file
            });
            newUploadedFiles.push(uploadedFile);
            setFiles(prevFiles => {
              const loadingFiles = prevFiles.loadingFiles.filter(prevFile => prevFile.name !== file.name);
              const uploadedFiles = [...prevFiles.uploadedFiles, uploadedFile];
              return {
                ...prevFiles,
                loadingFiles,
                uploadedFiles
              };
            });
          } catch (err) {
            let message: string = CustomDropzoneErrorCodes.FailedUpload;
            if (err instanceof TRPCClientError) {
              message = err.message;
            }
            setFiles(prevFiles => {
              const loadingFiles = prevFiles.loadingFiles.filter(prevFile => prevFile.name !== file.name);
              const rejectedFiles = [...prevFiles.rejectedFiles, {
                file,
                errorMessage: getErrorFromDropzoneCode({
                  errorCode: message
                })
              }];
              return {
                ...prevFiles,
                loadingFiles,
                rejectedFiles
              };
            });
          }
        }));
        onUploadedFilesChange?.([...files.uploadedFiles, ...newUploadedFiles]);

        // REJECTED FILES
        // Format the rejected files to match the format of the rejected files state
        const rejectedFilesFormatted = rejectedFiles.map(({
          file,
          errors
        }) => {
          const errorCode = errors[0].code;
          return {
            file,
            errorMessage: getErrorFromDropzoneCode({
              errorCode,
              ...(!!props.maxSize && errorCode === DropzoneErrorCode.FileTooLarge && {
                opts: {
                  maxSize: props.maxSize
                }
              }) // If the error is maxfile size, add the max file size to the error message
            })
          };
        });

        // Add the rejected files to the rejected files state
        setFiles(prevFiles => {
          return {
            ...prevFiles,
            rejectedFiles: [...prevFiles.rejectedFiles, ...rejectedFilesFormatted]
          };
        });
      }} />}
      </div>
    </FilesContext.Provider>;
});

// Agnostic version of the Dropzone component
const NativeDropzone = forwardRef<HTMLDivElement, DropzoneOptions & {
  isError?: boolean;
}>(function DropzoneWithS3({
  isError = false,
  ...dropzoneOptions
}, ref) {
  // Get the accepted extensions from the accepted mime types

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone(dropzoneOptions);
  return <div className="w-full">
      <div {...getRootProps({
      ref,
      className: clsx("w-full py-2.5 px-6 text-center flex items-center gap-2 justify-center", {
        "border-error-80 text-error": isDragReject || isError,
        "border-success-80 text-success": isDragAccept,
        "border-neutral-700 text-neutral-700": !isDragActive && !isError,
        "border-neutral-100 text-neutral-200": dropzoneOptions.disabled
      }, dropzoneOptions.disabled ? "cursor-default" : "cursor-pointer")
    })} tabIndex={-1}>
        <input {...getInputProps()} />
        {/* FIXME: Using an icon makes the drop hover bug. Seems like the ref shits */}
        {/* <CloudArrowUpIcon className="h-5 w-6 flex-none" /> */}
        <ArrowUpOnSquareIcon className="h-5 w-6 flex-none" />
        importer un ou plusieurs fichiers
      </div>
      {dropzoneOptions.accept && <Typography.body className="text-sm mt-2 text-center" textColor="text-neutral-600">
          Types de fichiers autorisés :{" "}
          {keys(dropzoneOptions.accept).map(mime_type => `.${mime.extension(mime_type)}`).join(", ")}
        </Typography.body>}
    </div>;
});
const DroppedFiles: React.FC = () => {
  const {
    files,
    readOnly
  } = useContext(FilesContext);
  const {
    uploadedFiles,
    loadingFiles,
    rejectedFiles
  } = files;
  return <aside className="w-full" data-sentry-component="DroppedFiles" data-sentry-source-file="dropzone.tsx">
      <ul className="space-y-2.5">
        {uploadedFiles.length !== 0 ? <UploadedFiles /> : <Typography.body className="flex flex-col text-center" textColor="text-neutral-500">
            {readOnly ? "Aucun document disponible" : "Vous n'avez pas encore importé de document"}
          </Typography.body>}
        {!readOnly && loadingFiles.length !== 0 && <LoadingFiles />}
        {!readOnly && rejectedFiles.length !== 0 && <RejectedFiles />}
      </ul>
    </aside>;
};
const FileLine: React.FC<React.ComponentPropsWithoutRef<"li">> = ({
  ...props
}) => {
  return <li className="flex items-center justify-between gap-6" {...props} data-sentry-component="FileLine" data-sentry-source-file="dropzone.tsx" />;
};
const RejectedFiles: React.FC = ({}) => {
  const {
    files
  } = useContext(FilesContext);
  return <>
      {files.rejectedFiles.map(rejectedFile => {
      return <FileLine key={rejectedFile.file.name}>
            <RejectedFile rejectedFile={rejectedFile} />
          </FileLine>;
    })}
    </>;
};
const RejectedFile: React.FC<React.PropsWithRef<{
  rejectedFile: RejectedFileType;
}>> = ({
  rejectedFile
}) => {
  const {
    setFiles
  } = useContext(FilesContext);
  const {
    file,
    errorMessage
  } = rejectedFile;
  return <>
      <div className="flex w-full justify-between gap-x-4">
        <div className="flex gap-x-4">
          <PaperClipIcon className="h-6 w-6 flex-none" data-sentry-element="PaperClipIcon" data-sentry-source-file="dropzone.tsx" />
          <Typography.error className="line-clamp-1" data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">{file.name}</Typography.error>
        </div>
        <Typography.error className="line-clamp-1 text-right font-bold" data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">{errorMessage}</Typography.error>
      </div>
      <div className="flex w-14 justify-end gap-1">
        <Button onClick={() => setFiles(prevFiles => ({
        ...prevFiles,
        rejectedFiles: prevFiles.rejectedFiles.filter(prevFile => prevFile.file.name !== file.name)
      }))} data-sentry-element="Button" data-sentry-source-file="dropzone.tsx">
          <TrashIcon className="h-6 w-6 text-black" data-sentry-element="TrashIcon" data-sentry-source-file="dropzone.tsx" />
        </Button>
      </div>
    </>;
};
const LoadingFiles: React.FC = () => {
  const {
    files
  } = useContext(FilesContext);
  return <>
      {files.loadingFiles.map(loadingFile => <FileLine key={loadingFile.name}>
          <LoadingFile loadingFile={loadingFile} />
        </FileLine>)}
    </>;
};
const LoadingFile: React.FC<React.PropsWithoutRef<{
  loadingFile: LoadingFileType;
}>> = ({
  loadingFile
}) => {
  return <>
      <div className="flex w-full justify-between gap-4">
        <div className="flex gap-4">
          <PaperClipIcon className="h-6 w-6 flex-none" data-sentry-element="PaperClipIcon" data-sentry-source-file="dropzone.tsx" />
          <Typography.body className="line-clamp-1" data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">{loadingFile.name}</Typography.body>
        </div>
        <Typography.body data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">loading...</Typography.body>
      </div>
      <div className="h-1 w-14" />
    </>;
};
const UploadedFiles: React.FC = () => {
  const {
    files
  } = useContext(FilesContext);
  return <>
      {files.uploadedFiles.map(uploadedFile => <FileLine key={uploadedFile.id}>
          <UploadedFile file={uploadedFile} />
        </FileLine>)}
    </>;
};
const UploadedFile: React.FC<React.PropsWithoutRef<{
  file: UploadedFileType;
}>> = ({
  file
}) => {
  const {
    files,
    readOnly,
    setFiles,
    onUploadedFilesChange,
    removeFromDBOnDelete
  } = useContext(FilesContext);
  const {
    uri,
    id,
    name
  } = file;
  const getFileQuery = trpc.files.getById.useQuery({
    id
  }, {
    enabled: false
  });
  const deleteMutation = trpc.files.delete.useMutation();
  const disabled = getFileQuery.isFetching || deleteMutation.isLoading || deleteMutation.isSuccess;
  const handleDelete = async () => {
    // TODO: files with same name get deleted in once
    const {
      data
    } = await getFileQuery.refetch();
    const isFileInDB = !!data?.id;
    if (isFileInDB && removeFromDBOnDelete) {
      await deleteMutation.mutateAsync({
        uri
      });
    }
    setFiles(prevFiles => {
      return {
        ...prevFiles,
        uploadedFiles: prevFiles.uploadedFiles.filter(file => file.id !== id)
      };
    });
    onUploadedFilesChange?.(files.uploadedFiles.filter(file => file.id !== id));
  };
  return <>
      <button type="button" className="group flex gap-x-4 " disabled={disabled} onClick={() => downloadFile(uri, name)}>
        <PaperClipIcon className="h-6 w-6 flex-none" data-sentry-element="PaperClipIcon" data-sentry-source-file="dropzone.tsx" />
        <Typography.body className="line-clamp-1 w-full text-left underline underline-offset-2 group-disabled:text-neutral-400 group-disabled:pointer-events-none" data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">
          {name}
        </Typography.body>
        {/* Hack to have the same space between an uploaded file and and a loading file. It simulates the space loading takes when it is loading so loading and uploaded files have the same length for the file title */}
        <Typography.body className="invisible" data-sentry-element="unknown" data-sentry-source-file="dropzone.tsx">loading...</Typography.body>
      </button>

      <div className="flex w-14 justify-end gap-1">
        <Button onClick={() => downloadFile(uri, name)} disabled={disabled} className="group" data-sentry-element="Button" data-sentry-source-file="dropzone.tsx">
          <DocumentArrowDownIcon className="h-6 w-6 text-black group-disabled:hidden" data-sentry-element="DocumentArrowDownIcon" data-sentry-source-file="dropzone.tsx" />
        </Button>
        {!readOnly &&
      // The files saved in jsonb in the DB won't have the "__typename" field, but this field is required for the permission to work properly
      <Can I="delete" this={{
        ...file,
        __typename: "files"
      }}>
            <Dialog.Root>
              <ConfirmDeletionDialog isDeleting={deleteMutation.isLoading} onConfirm={handleDelete} />
              <Dialog.Trigger asChild>
                <Button disabled={disabled} className="group">
                  <TrashIcon className="h-6 w-6 text-black group-disabled:text-neutral-400" />
                </Button>
              </Dialog.Trigger>
            </Dialog.Root>
          </Can>}
      </div>
    </>;
};
type LabeledDropzoneProps = DropzoneProps & LabeledInputProps;
export const LabeledDropzone: React.FC<LabeledDropzoneProps> = ({
  label,
  description,
  name,
  error,
  required,
  containerClassName,
  ...rest
}) => {
  return <InputContainer label={label} description={description} name={name} error={error} required={required} className={containerClassName} data-sentry-element="InputContainer" data-sentry-component="LabeledDropzone" data-sentry-source-file="dropzone.tsx">
      <Dropzone isError={Boolean(error)} {...rest} data-sentry-element="Dropzone" data-sentry-source-file="dropzone.tsx" />
    </InputContainer>;
};